.antd-pro-component-dashs-upload-image-style-contentImageUpload {
  position: relative;
}
.antd-pro-component-dashs-upload-image-style-divUpload {
  display: flex;
  flex-wrap: wrap;
}
.antd-pro-component-dashs-upload-image-style-itemImageTag {
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.antd-pro-component-dashs-upload-image-style-itemImageTagadd {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.antd-pro-component-dashs-upload-image-style-itemImage {
  position: relative;
}
.antd-pro-component-dashs-upload-image-style-itemImage:before {
  position: absolute;
  padding: 10px;
  z-index: 1;
  width: 100px;
  height: 100px;
  background-color: #00000080;
  opacity: 0;
  content: " ";
}
.antd-pro-component-dashs-upload-image-style-itemImage > .antd-pro-component-dashs-upload-image-style-groupIcon {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -28px;
  height: 50px;
  display: none;
  cursor: pointer;
}
.antd-pro-component-dashs-upload-image-style-itemImage > .antd-pro-component-dashs-upload-image-style-groupIcon > i {
  color: #fff;
  padding: 0 5px;
}
.antd-pro-component-dashs-upload-image-style-itemImage:hover:before {
  opacity: 1;
}
.antd-pro-component-dashs-upload-image-style-itemImage:hover > .antd-pro-component-dashs-upload-image-style-groupIcon {
  display: block;
}

.antd-pro-component-dashs-layout-header-fixedHeader {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  transition: width 0.2s;
}
.antd-pro-component-dashs-layout-header-header_layout_setting {
  height: 50px;
  background: transparent;
}
@media screen and (max-width: 767px) {
  .antd-pro-component-dashs-layout-header-header_layout_setting {
    height: 50px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-component-dashs-table-index-TableAntd {
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px #00000033;
  width: 'unset';
}
.antd-pro-component-dashs-table-index-TableAntd .ant-table {
  margin: unset !important;
}
.antd-pro-component-dashs-table-index-TableAntd .ant-table-thead > tr > th {
  font-weight: 500;
}
.antd-pro-component-dashs-table-index-TableAntd .ant-table-tbody > tr > td {
  color: #222222;
}
.antd-pro-component-dashs-table-index-TableAntd .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #d5eaf2;
}
.antd-pro-component-dashs-table-index-TableAntd .ant-table-tbody > tr[class*='footer'] > td {
  text-transform: uppercase;
}
.antd-pro-component-dashs-table-index-TableAntd .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #0078b6;
}
.antd-pro-component-dashs-table-index-TableAntd .ant-table-thead > tr > th {
  font-size: 14px;
  background: #0090da;
  color: #fff;
}
.antd-pro-component-dashs-table-index-TableAntd .ant-btn-ghost {
  border-width: 0px;
  box-shadow: unset;
}
.antd-pro-component-dashs-table-index-ecommerceOrdersSelect .ant-select-selector {
  border: 1px solid red !important;
}
.antd-pro-component-dashs-table-index-rowUnCheck {
  height: 50px;
  cursor: pointer;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.antd-pro-component-dashs-table-index-ModalIssue .ant-modal-body {
  padding: 10px 24px;
}
.antd-pro-component-dashs-table-index-ModalIssue .ant-modal-close-x {
  font-size: 1.5rem;
}
.antd-pro-component-dashs-table-index-ModalIssue .ant-modal-content {
  background-color: #fafafa;
}
.antd-pro-component-dashs-table-index-ModalIssue .ant-modal-title {
  font-size: 20px;
}
.antd-pro-component-dashs-table-index-styleRowle td {
  background: #f7f8f8;
}
.antd-pro-component-dashs-table-index-rowSpecial {
  background: #fefced;
  font-weight: bold;
}
.antd-pro-component-dashs-table-index-styleRowleActive {
  background: #fefced;
  font-weight: 500;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-component-dashs-top-nav-header-index-head {
  width: 100%;
  transition: background 0.3s, width 0.2s;
  height: 44px;
  position: relative;
  z-index: 1;
  background: #1888E8 !important;
  color: #fff;
  box-shadow: 0px 2px 4px #2e2e2e1f;
}
.antd-pro-component-dashs-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal {
  line-height: 44px;
  height: 100%;
  font-size: 14px;
  border: unset !important;
  box-shadow: unset !important;
  top: 0px !important;
}
.antd-pro-component-dashs-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-submenu-title {
  height: 100%;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  color: #fff !important;
}
.antd-pro-component-dashs-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-submenu-title span {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.antd-pro-component-dashs-top-nav-header-index-head .antd-pro-component-dashs-top-nav-header-index-main {
  display: flex;
  height: 44px;
  margin: auto;
  width: 95%;
  padding: 0 5px;
}
.antd-pro-component-dashs-top-nav-header-index-head .antd-pro-component-dashs-top-nav-header-index-main.antd-pro-component-dashs-top-nav-header-index-wide {
  max-width: 1200px;
  margin: auto;
  padding-left: 0;
}
.antd-pro-component-dashs-top-nav-header-index-head .antd-pro-component-dashs-top-nav-header-index-main .antd-pro-component-dashs-top-nav-header-index-left {
  flex: 1;
  display: flex;
  width: 75%;
  float: left;
}
.antd-pro-component-dashs-top-nav-header-index-logo {
  width: 30%;
  height: 44px;
  position: relative;
  line-height: 44px;
  transition: all 0.3s;
  overflow: hidden;
}
.antd-pro-component-dashs-top-nav-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
}
.antd-pro-component-dashs-top-nav-header-index-logo h1 {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  margin: 0 0 0 12px;
  font-weight: 400;
}
.antd-pro-component-dashs-top-nav-header-index-light h1 {
  color: #002140;
}
.antd-pro-component-dashs-top-nav-header-index-menu {
  border: none !important;
  height: 44px;
  line-height: 44px;
  width: 100%;
}
.antd-pro-component-dashs-top-nav-header-index-banhang {
  background: #0078b6;
  border-radius: 5px;
  padding: 0 20px;
  cursor: pointer;
}
.antd-pro-component-dashs-top-nav-header-index-banhang a {
  color: #fff;
}
.antd-pro-component-dashs-top-nav-header-index-banhang i {
  margin-right: 5px;
}
.antd-pro-component-dashs-top-nav-header-index-banhang:hover {
  background: #004872;
}
.antd-pro-component-dashs-top-nav-header-index-headTop {
  height: 50px;
  background: #fff;
  margin: auto;
  width: 100%;
  padding: 0 2.5vw;
}
.antd-pro-component-dashs-top-nav-header-index-headTop img {
  height: 100%;
  padding: 5px;
}
.antd-pro-component-dashs-top-nav-header-index-headTop .antd-pro-component-dashs-top-nav-header-index-setting {
  padding: 0 10px;
  color: #111;
  font-weight: 700;
  border-radius: 5px;
  display: inline-block;
  transition: all 0.1s ease-out;
  float: right;
}
.antd-pro-component-dashs-top-nav-header-index-headTop .antd-pro-component-dashs-top-nav-header-index-urlSite {
  cursor: pointer;
  background: #1887e84b;
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  display: initial;
}
.antd-pro-component-dashs-top-nav-header-index-headTop .antd-pro-component-dashs-top-nav-header-index-headTitle {
  height: 100%;
  width: auto;
  display: inline-flex;
}
.antd-pro-component-dashs-top-nav-header-index-headTop .antd-pro-component-dashs-top-nav-header-index-headTitle .antd-pro-component-dashs-top-nav-header-index-headTitleContent {
  line-height: initial;
  align-items: center;
  display: grid;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
}
.antd-pro-component-dashs-top-nav-header-index-headTop .antd-pro-component-dashs-top-nav-header-index-headTitle .antd-pro-component-dashs-top-nav-header-index-headTitleContent .antd-pro-component-dashs-top-nav-header-index-headTitleMain {
  font-weight: 700;
  color: #0CA6E1;
  font-size: 22px;
}
.antd-pro-component-dashs-top-nav-header-index-headTop .antd-pro-component-dashs-top-nav-header-index-headTitle .antd-pro-component-dashs-top-nav-header-index-headTitleContent .antd-pro-component-dashs-top-nav-header-index-headTitleSub {
  font-weight: bold;
}
.antd-pro-component-dashs-top-nav-header-index-menuSetting {
  border-radius: 5px;
}
.antd-pro-component-dashs-top-nav-header-index-menuSetting li {
  padding: 10px 35px;
}
.antd-pro-component-dashs-top-nav-header-index-menuSetting .ant-dropdown-menu-item:hover,
.antd-pro-component-dashs-top-nav-header-index-menuSetting .ant-dropdown-menu-submenu-title:hover {
  background-color: #eee !important;
}
.antd-pro-component-dashs-top-nav-header-index-menuSetting .ant-dropdown-menu-item:hover a,
.antd-pro-component-dashs-top-nav-header-index-menuSetting .ant-dropdown-menu-submenu-title:hover a {
  color: #222 !important;
}
.antd-pro-component-dashs-top-nav-header-index-selectChinhanh {
  width: 100%;
  border-left: 1px solid #bbb;
}
.antd-pro-component-dashs-top-nav-header-index-selectChinhanh .ant-select-selector {
  border: none !important;
  box-shadow: unset !important;
  border-radius: 0 !important;
  background: unset !important;
}
.antd-pro-component-dashs-top-nav-header-index-modalChinhanh .ant-modal-content {
  background-color: #fff !important;
}
.antd-pro-component-dashs-top-nav-header-index-modalChinhanh .ant-modal-header {
  border-radius: 10px 10px 0 0;
}
.antd-pro-component-dashs-top-nav-header-index-modalChinhanh .ant-modal-body {
  padding: 10px 20px;
}
.antd-pro-component-dashs-top-nav-header-index-colChinhanh {
  margin: 15px 0 20px;
  display: flex;
  background: #eff2f5;
  border-radius: 5px;
  align-items: center;
}
.antd-pro-component-dashs-top-nav-header-index-colChinhanh > i {
  font-size: 16px;
  cursor: pointer;
  width: 50px;
  text-align: center;
}
.antd-pro-component-dashs-top-nav-header-index-buttonChinhanh {
  border-color: #419543 !important;
  background-color: #419543 !important;
  color: #fff !important;
}
.antd-pro-component-dashs-top-nav-header-index-buttonChinhanhcancel {
  border-color: #cecece !important;
  background-color: #cecece !important;
  color: #222 !important;
}

.mainlayout {
  background: #EEEEEE;
  min-height: calc(100vh - 94px);
}
.mainlayout .heading-page {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}
.mainlayout .box-filter {
  margin-bottom: 15px;
  background-color: #fff;
  position: relative;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px 0px;
}
.mainlayout .box-filter__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  padding: 0 12px;
  font-size: 13px;
  font-weight: bold;
  color: #111;
  background: transparent;
  margin-bottom: 0;
}
.mainlayout .box-filter__main {
  padding: 10px 12px 0;
  position: relative;
}
.mainlayout .box-filter__main .ant-select-selector,
.mainlayout .box-filter__main .ant-picker {
  border: unset !important;
  box-shadow: unset !important;
  border-radius: unset !important;
  border-bottom: 1px solid #bababa !important;
}
.mainlayout .box-filter__main .ant-input {
  border: unset !important;
  box-shadow: unset !important;
  border-bottom: 1px solid #bababa !important;
  border-radius: unset !important;
}
.mainlayout .box-filter .ant-radio-wrapper > span:nth-child(2) {
  color: #111111;
  font-size: 13px;
}
.mainlayout .layout-actions {
  margin-bottom: 15px;
}
.mainlayout .header-filter-search {
  width: 450px;
  min-width: 200px;
  margin-right: 10px;
  flex-shrink: 0;
}
.mainlayout .header-filter-search .ant-select-selector,
.mainlayout .header-filter-search .ant-input-affix-wrapper,
.mainlayout .header-filter-search .ant-input {
  border-radius: 5px !important;
}
.mainlayout .header-filter-button {
  display: flex;
  align-items: center;
}
.mainlayout .layout-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainlayout .showhideicon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
}
.mainlayout .showhideicon:hover {
  background-color: #e5e5e5;
}
.filter-zone .box-filter {
  margin-bottom: 15px;
  background-color: #fff;
  position: relative;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px 0px;
}
.filter-zone .box-filter__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  padding: 0 12px;
  font-size: 13px;
  font-weight: bold;
  color: #111;
  background: transparent;
  margin-bottom: 0;
}
.filter-zone .box-filter__main {
  padding: 10px 12px 0;
  position: relative;
}
.filter-zone .box-filter__main .ant-select-selector,
.filter-zone .box-filter__main .ant-picker {
  border: unset !important;
  box-shadow: unset !important;
  border-radius: unset !important;
  border-bottom: 1px solid #bababa !important;
}
.filter-zone .box-filter__main .ant-input {
  border: unset !important;
  box-shadow: unset !important;
  border-radius: unset !important;
}
.filter-zone .box-filter__main .ant-input-affix-wrapper {
  border-bottom: 1px solid #bababa !important;
}
.filter-zone .box-filter__main .ant-select-arrow,
.filter-zone .box-filter__main .ant-select-clear,
.filter-zone .box-filter__main .ant-input-suffix {
  margin-right: -10px;
}
.filter-zone .box-filter__main .ant-select-selector {
  background-color: #fff !important;
  cursor: default !important;
}
.filter-zone .box-filter .ant-radio-wrapper > span:nth-child(2) {
  color: #111111;
  font-size: 13px;
}
.filter-zone .box-filter .ant-input-affix-wrapper:focus,
.filter-zone .box-filter .ant-input-affix-wrapper-focused {
  box-shadow: none;
  border: none;
}
.mainlayout_container {
  margin: 0 auto;
  padding: 15px 2.5vw;
}
.actionBtn {
  color: #fff !important;
  background-color: #4bac4d !important;
  border: none !important;
  margin-left: 10px;
}
.actionBtn > i {
  margin-right: 10px;
}
.actionBtn:hover {
  background-color: #419543 !important;
  color: #fff !important;
}
.actionBtn.danger {
  background-color: #db4e65 !important;
}
.actionBtn.danger:hover {
  background-color: #d5324d !important;
}
.actionBtn.default {
  background-color: #898c8d !important;
}
.actionBtn.default:hover {
  background-color: #787b7d !important;
}
.datePickerBorder_in {
  width: 100%;
  border: none !important;
  box-shadow: unset !important;
  border-radius: 5px !important;
  background: #eff2f5 !important;
  height: 32px;
}
.selectBorder_in {
  width: 100%;
}
.selectBorder_in .ant-select-selector {
  border: none !important;
  box-shadow: unset !important;
  border-radius: 5px !important;
  background: #eff2f5 !important;
}
.inputBorder {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  box-shadow: unset !important;
  border-radius: unset !important;
}
.inputNumberNoBorder {
  border: none !important;
  border-bottom: 1px solid #bababa !important;
  box-shadow: unset !important;
  border-radius: unset !important;
}
.inputNumberNoBorder .ant-input-number-handler-wrap {
  display: none;
}
.inputNumberNoBorder.right input {
  text-align: right;
}
.inputNumberNoBorder.right .ant-input-number-input {
  padding: 0 !important;
}
.inputNumberNoBorder.warning {
  color: red !important;
  border-color: red !important;
}
.selectBorder .ant-select-selector {
  border: none !important;
  border-bottom: 1px solid #bababa !important;
  box-shadow: unset !important;
  border-radius: unset !important;
}
.datePickerBorder {
  border: none;
  border-bottom: 1px solid #bababa;
  box-shadow: unset !important;
  border-radius: unset !important;
}
.dropDownBtn {
  margin-left: 10px;
  width: 32px;
  height: 32px;
  min-width: 32px;
  border: 1px solid #d7d7d7;
  padding-left: 0;
  padding-right: 0;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
}
.commonModalSocial .ant-modal-header {
  background: #fff;
}
.commonModalSocial .ant-modal-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.commonModalSocial .ant-tabs-nav {
  background: #fff;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
  margin: 20px 10px;
  border-radius: 12px;
  padding: 12px 15px;
  height: 100%;
}
.commonModalSocial .ant-tabs-right > .ant-tabs-content-holder {
  border-right: unset;
}
.commonModal {
  overflow-x: hidden;
}
.commonModal .ant-input,
.commonModal .ant-picker,
.commonModal .ant-input-number,
.commonModal .ant-select-selector,
.commonModal .ant-input-password {
  border: unset !important;
  box-shadow: unset !important;
  border-bottom: 1px solid #bababa !important;
  border-radius: unset !important;
  background-color: unset !important;
}
.commonModal .ant-input > .ant-input,
.commonModal .ant-picker > .ant-input,
.commonModal .ant-input-number > .ant-input,
.commonModal .ant-select-selector > .ant-input,
.commonModal .ant-input-password > .ant-input {
  border: unset !important;
}
.commonModal .ant-input-number .ant-input-number-handler-wrap {
  display: none;
}
.commonModal .ant-comment-inner {
  padding: 0;
}
.commonModal .ant-modal-header {
  background-color: #ededed;
}
.commonModal .ant-modal-title {
  font-size: 15px;
  color: #111;
  font-weight: 700;
}
.commonModal .ant-modal-body {
  background-color: #fff;
  margin-top: -9px;
  padding: 0;
}
.commonModal .ant-modal-footer {
  background-color: #fff;
}
.commonModal .contentModal {
  padding: 10px 28px 0;
  max-height: 80vh;
  overflow: auto;
}
.commonModal .contentModals {
  padding: 10px 28px 0;
  overflow: auto;
}
.commonModal .questions_item_row {
  margin-bottom: 15px;
}
.commonModal .questions_item_header {
  padding: 10px;
  background: #1888E8;
  color: #fff;
  border-radius: 10px 10px 0 0;
  font-size: 18px;
}
.commonModal .questions_item {
  border: 1px solid #bbb;
  border-radius: 0 0 10px 10px;
}
.commonModal .info_surveys {
  border-bottom: 1px dotted #bbb;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 15px;
}
.commonModal .info_surveys span {
  margin-left: 10px;
}
.commonModal .ant-tabs-nav {
  background-color: #fff;
}
.commonModal .ant-tabs-tab {
  font-size: 14px;
  padding: 0 32px;
  font-weight: 400;
  line-height: 31px;
  margin: 0;
  color: #666;
}
.commonModal .ant-tabs-tab.ant-tabs-tab-active {
  font-size: 14px;
  color: #010101;
  font-weight: 400;
}
.commonModal .ant-tabs-ink-bar {
  background: #4bac4d;
}
.commonModal .info-icon {
  font-size: 16px;
  cursor: pointer;
  color: #999;
  margin-left: 10px;
}
.commonModal .info-icon:hover {
  color: #666;
}
.commonModal .ant-form-item-label > label {
  color: #222;
  font-weight: 500;
  font-size: 14px !important;
  text-transform: unset;
}
.addColumnModal {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  width: 410px;
  padding: 10px;
  z-index: 111;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px;
}
.panel {
  background-color: #fff;
  border: 1px solid #ccc;
}
.panel .lablePanel {
  background-color: #ececee;
  font-weight: 700;
  color: #333;
  padding: 5px 15px;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
.panel .bodyPanel {
  padding: 0px 0 10px;
}
.renderFormFilter .ant-form-item {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px 0px;
}
.renderFormFilter label {
  height: 24px;
  font-size: 13px !important;
  font-weight: bold !important;
  color: #111;
  background: transparent;
  margin-bottom: 0;
  text-transform: inherit;
}
.renderFormFilter .ant-input,
.renderFormFilter .ant-picker,
.renderFormFilter .ant-input-number,
.renderFormFilter .ant-select-selector {
  border: unset !important;
  box-shadow: unset !important;
  border-bottom: 1px solid #bababa !important;
  border-radius: unset !important;
}
.headerPrint {
  height: 40px;
  line-height: 40px;
  display: flex;
}
.headerPrint input {
  margin-left: 10px !important;
  width: 250px;
  background: #eae8e8 !important;
  border: unset !important;
  box-shadow: unset !important;
  border-bottom: 3px solid #4bac4d !important;
  border-radius: unset !important;
}
.headerPrint .fas {
  float: right;
  margin-top: 10px;
  cursor: pointer;
}
.headerPrint .fa-pen {
  margin: 10px 10px 0;
}
.selectPrint {
  width: 250px;
  margin-left: 10px !important;
}
.selectPrint .ant-select-selector {
  background: #eae8e8 !important;
  border: unset !important;
  box-shadow: unset !important;
  border-bottom: 3px solid #4bac4d !important;
  border-radius: unset !important;
}
.dropdownClassNameHome {
  width: auto !important;
}
.pricelist {
  border-bottom: 1px solid #bababa;
  width: 100%;
  display: block;
  text-align: right;
  cursor: text;
}
.buttonGia {
  cursor: pointer;
  width: 33px;
  line-height: 25px;
  border: none;
  /* padding: 2px 10px; */
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-weight: 400;
  background-color: #9fa2a3;
  color: #fff;
  display: inline-block;
  text-align: center;
  margin: 0 2px;
}
.buttongroupsGia {
  align-items: center;
  /* align-self: center; */
  display: flex;
  height: 100%;
  justify-content: center;
}
.itemexpanded {
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 0;
  display: flex;
  text-align: right;
}
.itemexpanded .itemLableexpanded {
  width: 30%;
  text-align: left;
}
.itemexpanded:last-child {
  border-bottom: unset;
}
.ghichu {
  padding: 10px 0;
  display: flex;
  font-style: italic;
}
.ghichu .itemLableexpanded {
  width: 30%;
}
.actionBtnMophieu {
  margin-left: 10px;
  color: #ffffff !important;
  background-color: #4bac4d !important;
  border: none !important;
  position: absolute !important;
  bottom: 20px;
  right: 20px;
}
.actionBtnMophieu > i {
  margin-right: 10px;
}
.actionBtnthanhtoan {
  margin-left: 10px;
  color: #ffffff !important;
  background-color: #4bac4d !important;
  border: none !important;
  float: right;
  margin-top: 10px;
}
.actionBtnthanhtoan > i {
  margin-right: 10px;
}
.actionBtnthanhtoando {
  margin-left: 10px;
  color: #ffffff !important;
  background-color: #db4e65 !important;
  border: none !important;
  float: right;
  margin-top: 10px;
}
.actionBtnthanhtoando > i {
  margin-right: 10px;
}
.cashTop {
  background: #f9f9f9;
  border: 1px solid #e7e7e7;
  padding: 0.5rem 1.5rem 0.8rem;
  margin-bottom: 15px;
  display: flow-root;
}
.cashTop ul {
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
}
.cashTop ul li {
  float: left;
  text-align: right;
  font-size: 16px;
  font-weight: 700;
  margin-left: 5rem;
}
.cashTop ul li h3 {
  font-weight: 500;
  padding-bottom: 2px;
  font-size: 16px;
  margin: 0;
}
.cashTop ul li:first-child {
  margin-left: 0;
}
.contentReport {
  margin-bottom: 15px;
  background: #fff;
  padding: 10px;
}
.contentReport .titleReport {
  font-size: 17px;
  text-align: center;
  margin-bottom: 15px;
}
.headerSearch {
  background-color: #dcf4fc;
  border: 1px solid #c5e7f2;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
}
.bangxemhang_item {
  width: 100%;
}
.bangxemhang_item_name,
.bangxemhang_item_name_top {
  font-weight: 500;
  font-size: 16px;
}
.bangxemhang_item_quantity,
.bangxemhang_item_quantity_top {
  color: #999;
  font-style: italic;
}
.bangxemhang_item_stt_top,
.bangxemhang_item_stt {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: #bbb;
  border-radius: 50%;
}
.bangxemhang_item_stt_top {
  color: #fff;
  background: #e67225;
}
.dashboardStatisticRight {
  display: grid;
}
.dashboardStatisticRight .ant-card {
  border-radius: 5px;
  margin-bottom: 1.5rem;
}
.dashboardStatisticRight .ant-card-body {
  height: 123px;
}
.dashboardStatisticRight .ant-card-body p {
  font-size: 15px;
  /* font-weight: 500; */
  color: #fff;
}
.dashboardStatisticRight .ant-card-head-title {
  color: #fff;
}
.dashboardStatisticRight .ant-card-body strong {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  float: right;
  font-weight: 600;
}
.dashboardStatisticRight .warehouse {
  background: #4bac4d;
}
.dashboardStatisticRight .stopwatch {
  background: #f94f5e;
}
.dashboardStatisticRight .hourglass {
  background: #18c0c2;
}
.fullscreen-modal .ant-modal-header {
  background-color: #1888e8;
}
.fullscreen-modal .ant-modal-body {
  height: calc(100vh - 56px);
  overflow-y: auto;
  padding: 15px 25px;
  background-color: #eeeeee;
}
.fullscreen-modal .ant-modal-close-x {
  color: #fff;
}
.fullscreen-modal__title {
  font-size: 20px;
  color: #fff;
  margin: 0;
  line-height: 100%;
  margin-bottom: 10px;
  padding-left: 5px;
}
.fullscreen-modal input.fullscreen-modal__search {
  border-radius: 5px !important;
}
.fullscreen-modal__bars {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  margin-top: -10px;
  /* margin-bottom: 10px; */
}
.fullscreen-modal__bars:hover,
.fullscreen-modal__bars:active,
.fullscreen-modal__bars:focus {
  background-color: transparent;
  color: #fff;
}
@media (max-width: 1280px) {
  .mainlayout .header-filter-search {
    width: 380px;
  }
}
@media (max-width: 1023px) {
  .actionBtn span:nth-child(2) {
    display: none;
  }
  .mainlayout .header-filter-search {
    width: 300px;
  }
}
@media (min-width: 768px) {
  .mainlayout .heading-mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  .mainlayout .layout-actions {
    flex-direction: column;
    align-items: flex-start;
  }
  .mainlayout .header-filter-search {
    margin-bottom: 10px;
  }
  .mainlayout .heading-page {
    display: none;
  }
  .mainlayout .heading-mobile {
    display: block;
  }
  .mainlayout .layout-right {
    margin-bottom: 15px;
  }
  .actionBtn {
    margin-left: 0;
    margin-right: 5px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.buttonModalFilter {
  padding: 5px;
  background: #fff;
  cursor: pointer;
  display: none;
}
.dropdownComment {
  display: block;
  width: 100%;
  padding: 10px 20px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdownComment:hover {
  color: #1e2125;
  text-decoration: none;
  background-color: #e9ecef;
}
.radioDateCustom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.radioDateCustom:nth-child(1) {
  margin-bottom: 10px;
}
.radioDateCustom__label {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-color: #bababa; */
  border-bottom: 1px solid #bababa;
  color: #111;
  font-size: 13px;
  cursor: pointer;
  margin-left: 14px;
  margin-right: 4px;
}
.radioDateCustom__label > i {
  color: #666;
  font-size: 14px;
}
.sortTimeHeight {
  position: absolute;
  top: 50%;
  left: calc(100% + 35px);
  transform: translateY(-50%);
  background-color: #fff;
  z-index: 15;
  width: 495px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px;
  border-radius: 5px;
  padding: 20px 20px 5px;
}
.sortTimeHeight__title {
  font-size: 12px;
  font-weight: 700;
  color: #3f3f3f;
  margin-bottom: 15px;
  padding: 0;
}
.sortTimeHeight__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sortTimeHeight__item {
  margin-bottom: 15px;
  color: #056bd3;
  cursor: pointer;
  font-size: 13px;
}
.sortTimeHeight__item:hover {
  text-decoration: underline;
}
.sortTimeHeight::after {
  content: "";
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  border-right: 8px solid #fff;
  position: absolute;
  top: 50%;
  left: -8px;
  transform: translateY(-50%);
}
.detailProduct {
  overflow-y: auto;
  min-height: 30vh;
  max-height: 60vh;
}
.detailProduct .detailProduct__images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.detailProduct .detailProduct__infoImg {
  margin-bottom: 10px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  width: 100px;
  height: 100px;
}
.detailProduct .detailProduct__infoImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.detailProduct .description {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.detailProduct .description_label {
  font-size: 18px;
  color: #000;
  margin-bottom: 5px;
  line-height: 1.2;
}
.detailProduct .description_content {
  white-space: pre-wrap;
}
.baseModal .ant-modal-body {
  background-color: #fff;
}
.baseModal .ant-modal-footer {
  background-color: #fff;
}
.errorSelect .ant-select-selector {
  border: 1px solid red !important;
}
.khoangGiaBody {
  display: flex;
  flex-wrap: wrap;
}
.khoangGiaItem {
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #333;
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 17px;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 8px 9px;
  background: #fff;
}
.khoangGiaItem_active {
  border: 1px solid #1888E8;
  color: #1888E8;
}
.textProductstatic {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
}
.customTabs .ant-tabs-tab {
  padding-top: 0 !important;
}
.cardCustom {
  max-height: 80vh;
  overflow: scroll;
  width: 70%;
  margin: 0 auto !important;
  box-shadow: 4px 4px 22px 0 rgba(0, 0, 0, 0.06);
}
.modal_selectNganhHang .ant-modal-body {
  background: #f6f6f6;
}
.modal_selectNganhHang .ant-modal-footer {
  background: #fff;
}
.modal_selectNganhHang .selectNganhHang {
  overflow: hidden;
  position: relative;
}
.modal_selectNganhHang .selectNganhHang__main {
  display: flex;
  margin-top: 16px;
  height: 68vh;
  background-color: #fff;
  width: 100%;
  overflow: auto;
  border: 1px solid #c7cbd0;
  box-shadow: 4px 4px 22px 0 rgba(0, 0, 0, 0.06);
}
.modal_selectNganhHang .selectNganhHang__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 14.2857%;
  border-right: 1px solid #c7cbd0;
  flex-shrink: 0;
}
.modal_selectNganhHang .selectNganhHang__list_header {
  background: #f3f3f3;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.67px;
  font-size: 12px;
  border-bottom: 1px solid #c7cbd0;
  height: 32px;
  line-height: 32px;
  padding: 0 11px;
  width: 100%;
  color: rgba(34, 34, 34, 0.5);
}
.modal_selectNganhHang .selectNganhHang__list {
  overflow-y: auto;
  flex-shrink: 0;
  height: calc(100% - 32px);
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}
.modal_selectNganhHang .selectNganhHang__list::-webkit-scrollbar {
  width: 8px;
}
.modal_selectNganhHang .selectNganhHang__list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ddd;
}
.modal_selectNganhHang .selectNganhHang__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 20px;
}
.modal_selectNganhHang .selectNganhHang__list .scroller {
  overflow-y: scroll;
  scrollbar-color: #d8d8d8;
  width: 8px;
  border-radius: 20px;
}
.modal_selectNganhHang .selectNganhHang__list .selectNganhHang__item {
  width: 100%;
  cursor: pointer;
  line-height: 32px;
  padding: 0 11px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal_selectNganhHang .selectNganhHang__list .selectNganhHang__item p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal_selectNganhHang .selectNganhHang__list .selectNganhHang__item > i {
  font-size: 14px;
  color: #888;
}
.modal_selectNganhHang .selectNganhHang__list .selectNganhHang__item:hover,
.modal_selectNganhHang .selectNganhHang__list .selectNganhHang__item.active {
  background: #e6eef6;
}
.modal_selectNganhHang .selectNganhHang__selected {
  font-size: 14px;
  color: #888;
}
.modal_selectNganhHang .selectNganhHang__selected .selectNganhHang__selectedName {
  color: #1791f2;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .modal_selectNganhHang .selectNganhHang__list {
    width: 100%;
  }
}
.selectTabs_component .selectNganhHang__main {
  box-shadow: none;
  border: none;
  overflow-y: hidden;
  overflow-x: auto;
}
.selectTabs_component .selectNganhHang__box {
  width: 25%;
  border: 1px solid #c7cbd0;
}
.selectTabs_component .selectNganhHang__item > input {
  border: none;
  border-bottom: 1px solid #d9d9d9;
}
.selectTabs_component .ant-spin-nested-loading {
  width: 100%;
  height: 100%;
}
.selectTabs_component .ant-spin-container {
  height: 100%;
}
.Nomodal_selectNganhHang .selectNganhHang__main {
  height: 60vh;
}
.modalAddEdit .ant-modal-close {
  top: 30px;
  right: 14px;
}
.modal .ant-modal-body,
.modal .ant-modal-footer {
  background: #fff;
}
.testPng {
  position: fixed;
  top: 0;
  left: -100%;
}
.notices_input {
  display: flex;
  align-items: center;
  color: #f4791c;
  font-size: 14px;
  margin-top: 20px;
}
.notices_input > i {
  font-size: 16px;
  margin-right: 10px;
}
.notices_input > p {
  margin: 0;
  padding: 0;
}
.reportFunction {
  width: 100%;
}
.reportFunction ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.reportFunction p {
  margin: 0;
}
.reportFunction .w100 {
  width: 100%;
}
.reportFunction .containerReport {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  height: 74vh;
  overflow-y: auto;
  background: #fff;
  box-shadow: 4px 4px 22px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #eeeeee;
  padding: 30px;
}
.reportFunction .reportFunction_left .headerLeft {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.reportFunction .reportFunction_left .headerLeft .titleLeft {
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 0.83px;
  margin: 0;
  padding: 0;
  margin-right: 20px;
}
.reportFunction .reportFunction_left .headerLeft .button_titleLeft {
  height: 30px;
  background: #fff;
  color: #222;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}
.reportFunction .reportFunction_left .reportFunction_tabs .tabsGroup_title {
  font-size: 12px;
  font-weight: 700;
  color: #000;
  opacity: 0.5;
  letter-spacing: 0.67px;
  margin-bottom: 6px;
  text-transform: uppercase;
  padding: 0 10px;
}
.reportFunction .reportFunction_left .reportFunction_tabs .tabsGroup_list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.reportFunction .reportFunction_left .reportFunction_tabs .tabsGroup_item {
  font-size: 14px;
  font-weight: 400;
  color: #222;
  letter-spacing: 0.67px;
  letter-spacing: 0.4px;
  cursor: pointer;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reportFunction .reportFunction_left .reportFunction_tabs .tabsGroup_item > i {
  font-size: 14px;
  margin-left: 10px;
  color: #848484;
}
.reportFunction .reportFunction_left .reportFunction_tabs .tabsGroup_item:hover {
  background: #eeeeee;
}
.reportFunction .reportFunction_left .reportFunction_tabs .tabsGroup_item_active {
  background: #eeeeee;
  font-weight: bold;
}
.reportFunction .reportFunction_left .reportFunction_tabs .tabsGroupBottom {
  margin-top: 24px;
}
.reportFunction .reportFunction_right .reportFunction_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.reportFunction .reportFunction_right .reportFunction_header .titleRight {
  font-size: 35px;
  font-weight: 300;
  color: #333333;
}
.reportFunction .reportFunction_right .reportFunction_header .header_button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.reportFunction .reportFunction_right .reportFunction_header .header_button_primary {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1888E8;
  border-radius: 4px;
  font-size: 15px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  width: 145px;
  cursor: pointer;
}
.reportFunction .reportFunction_right .reportFunction_header .header_button_save {
  display: flex;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
}
.reportFunction .reportFunction_right .reportFunction_header .header_button_save .dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  outline: none;
  box-shadow: 0 3px 6px -4px #0000001f 1f, 0 6px 16px #00000014 14, 0 9px 28px 8px #0000000d 0d;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #fff;
  background-clip: padding-box;
}
.reportFunction .reportFunction_right .reportFunction_header .header_button_save .dropdownMenu .dropdown_item {
  color: #222;
  font-size: 14px;
  padding: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s height cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.1s padding cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.3s opacity cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.1s visibility cubic-bezier(0.73, 0.32, 0.34, 1.5);
}
.reportFunction .reportFunction_right .reportFunction_header .header_button_save .dropdownMenu .dropdown_item:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
.reportFunction .reportFunction_right .reportFunction_header .header_button_save .dropdownMenu.active > .dropdown_item {
  padding: 5px 10px;
  height: 35px;
  opacity: 1;
  visibility: visible;
}
.reportFunction .reportFunction_right .reportFunction_header .header_button_text {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #d0d0d0;
  height: 100%;
  width: 185px;
  color: #222;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}
.reportFunction .reportFunction_right .reportFunction_header .header_button_icon {
  color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 100%;
  width: 40px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain {
  padding-right: 100px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .reportFunction_right_title {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #222;
  margin-bottom: 20px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .filter_right_header {
  font-size: 23px;
  color: #333333;
  margin-bottom: 8px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .filter_right_instruction {
  color: #848484;
  font-size: 13px;
  margin-bottom: 10px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .listCheck_actions {
  display: flex;
  margin-top: 8px;
  align-items: center;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .listCheck_actions .listCheck_btn {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.52;
  cursor: pointer;
  height: 30px;
  padding: 0 15px;
  border: 1px solid #d0d0d0;
  margin-right: 11px;
  line-height: 30px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .listCheck_actions .listCheck_disable {
  cursor: not-allowed;
  background: #f5f5f5;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .listCheck_actions .listCheck_restore > i {
  cursor: pointer;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .notice {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .notice i {
  cursor: pointer;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .notice .notice_checkbox {
  margin-right: 7px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .notice .notice_checkbox .ant-checkbox + span {
  font-size: 14px;
  color: #848484;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .filter_left .filter_right_header {
  margin-bottom: 20px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .filter_formItem {
  margin-bottom: 20px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .filter_formItem_label {
  font-weight: bold;
  font-size: 12px;
  color: #222;
  letter-spacing: 0.67px;
  opacity: 0.5;
  text-transform: uppercase;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .filter_radio span.ant-radio + span {
  font-size: 14px;
  color: #848484;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .filter_unit_title {
  font-size: 23px;
  color: #333;
  margin-bottom: 15px;
  line-height: 30px;
  margin-top: 25px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .selectAdd {
  margin-top: 10px;
  padding: 0 28px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .selectAdd .selectAdd_item {
  display: flex;
  align-items: center;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .selectAdd .selectAdd_item:not(:last-child) {
  margin-bottom: 10px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .selectAdd .selectAdd_item i {
  cursor: pointer;
  font-size: 14px;
  color: #757575;
  margin-left: 23px;
}
.reportFunction .reportFunction_right .reportFunction_rightMain .selectAdd .selectAdd_item_select {
  width: 200px;
}
.titleChart {
  font-size: 16px;
  font-weight: 500;
  color: #222;
  margin-bottom: 10px;
}
.modalReport .ant-modal-body,
.modalReport .ant-modal-footer {
  background: #fff;
}
.modalReport .ant-modal-title {
  font-size: 30px;
  letter-spacing: 1.94px;
  color: #222;
  font-weight: 300;
}
.modalReport .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background: #f3f3f3;
  color: #222222;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.77;
  text-transform: uppercase;
}
.modalReport .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  color: #222222;
  background: #fff;
}
.modalReport_bodyContainer {
  height: 65vh;
}
.modalReport_footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modalReport_footer_result {
  font-size: 14px;
  font-weight: 700;
  color: #222;
}
.modalReport_footer_btn {
  display: flex;
  align-items: center;
}
.modalReport_extraTabs {
  font-size: 14px;
  color: #222;
}
.modalReport_left_title {
  font-size: 18px;
  color: #222;
  font-weight: 700;
  margin-bottom: 15px;
}
.modalReport_left_checkbox .ant-checkbox + span {
  font-size: 14px;
  color: #848484;
}
.modalReport_right {
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}
.modalReport_right .modalReport_main {
  width: 321px;
}
.modalReport_right .modalReport_main .modalReport_alert1 {
  font-size: 23px;
  color: #333333;
  text-align: center;
  line-height: 30px;
}
.modalReport_right .modalReport_main .modalReport_alert2 {
  font-size: 14px;
  color: #848484;
  line-height: 20px;
  margin-top: 10px;
  text-align: center;
}
.modalReport_footer_btnBack {
  border: 1px solid #d0d0d0;
  width: 120px;
  height: 40px;
  font-size: 15px;
  letter-spacing: 0.6px;
  color: #222;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 20px;
}
.modalExportReport {
  position: relative;
}
.modalExportReport_title {
  font-size: 35px;
  font-weight: 300;
  letter-spacing: 1.93px;
  color: #222;
  line-height: 42px;
}
.modalExportReport_text {
  font-size: 14px;
  letter-spacing: 0.78px;
  color: #222;
  margin-top: 33px;
}
.modalExportReport_input {
  margin: 16px 0;
}
.modalReport_footer_btnExport {
  width: 120px;
  height: 40px;
  font-size: 15px;
  letter-spacing: 0.6px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}
.listCheck_item {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.listCheck_item .ant-checkbox + span {
  font-size: 14px;
  color: #333;
}
.listCheck_item i {
  font-size: 14px;
  margin-right: 10px;
  color: #848484;
}
.listCheck_item p {
  font-size: 14px;
  color: #333;
  opacity: 0;
  margin: 0;
}
.listCheck_item:hover {
  background: #eeeeee;
}
.listCheck_item:hover p {
  opacity: 1;
}
.listCheck_itemDiv {
  display: flex;
  align-items: center;
}
.addDrawer .ant-drawer-title {
  font-size: 25px;
  color: #222;
  line-height: 30px;
  margin-top: 10px;
}
.addDrawer .ant-drawer-close {
  top: -6px;
}
.addDrawer .ant-drawer-body {
  padding: 0;
}
.addDrawer .ant-tabs-nav {
  margin-bottom: 0;
  padding: 0 30px;
  background-image: linear-gradient(180deg, #fff, #f6f6f6);
}
.addDrawer .ant-tabs-tab {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  color: #222;
}
.addDrawer .ant-tabs-tab-active {
  color: #222;
}
.addDrawer .ant-tabs-ink-bar {
  height: 4px;
  background: #f4791c;
}
.addDrawer .addDrawer_checkbox {
  margin-top: 30px;
  padding: 0 30px;
}
.addDrawer .addDrawer_checkbox_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.addDrawer .addDrawer_checkbox_item .short_hand {
  margin-left: 10px;
  font-size: 13px;
  color: #848484;
  font-style: italic;
}
.addDrawer .addDrawer_checkbox_item .wrapper_checkbox .ant-checkbox + span {
  font-size: 14px;
  color: #848484;
}
.headerLabelRenderForm {
  font-size: 25px;
  color: #222;
  letter-spacing: 0.833333px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 25px;
}
.wrapperTable {
  margin-top: 0px;
}
.rateCustom .ant-rate-star-zero .ant-rate-star-first {
  color: #fff;
}
.rateCustom .ant-rate-star-zero .ant-rate-star-second {
  color: #fff;
}
.wrapperTableSetting {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px 0px;
}
.wrapperTableSetting .rowSetting {
  margin-bottom: 10px;
  border-bottom: 1px dotted;
  padding-bottom: 20px;
}
.wrapperTableSetting .rowSetting img {
  display: block;
  margin-top: 10px;
  width: 100%;
}
.wrapperTableSetting .titleSetting {
  font-size: 18px;
  font-weight: 500;
}
.wrapperTableSetting .templateSetting {
  align-items: center;
  display: flex;
}
.wrapperTableSetting .ant-select {
  width: 350px;
  margin-left: 10px;
}
.wrapperTableSetting .ant-select-selector {
  border: unset !important;
  background: #1887e84b !important;
  box-shadow: unset !important;
  border-radius: 5px !important;
}
.selectSettingL {
  background: unset;
}
.selectSettingL .ant-select-selector {
  border: unset !important;
  background: #fff !important;
  box-shadow: unset !important;
  border-radius: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px 0px !important;
}
.tableListForm {
  width: 100%;
  background-color: transparent;
  display: inline-grid;
}
.tableListForm .ant-form-item {
  margin-bottom: 15px;
  margin-right: 0;
  display: flex;
}
.tableListForm .ant-form-item > .ant-form-item-label {
  width: auto;
  line-height: 25px;
  padding-right: 8px;
}
.tableListForm .ant-form-item .ant-form-item-label > label {
  font-size: 14px;
}
.tableListForm .ant-form-item .ant-form-item-control {
  line-height: 32px;
}
.tableListForm .ant-form-item-control-wrapper {
  flex: 1;
}
.newTableListForm .ant-form-item > .ant-form-item-label {
  padding-bottom: 5px;
  line-height: 18px;
}
.newTableListForm .ant-form-item label {
  height: unset;
}
.newTableListForm input {
  width: 100%;
}
.modalResizeImg .ant-modal-body,
.modalResizeImg .ant-modal-footer,
.modalResizeImg .ant-card-body {
  background: #fff;
}
.wrapperUserAddress {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.wrapperUserAddress_label {
  width: 110px;
  font-size: 14px;
  margin-right: 20px;
  color: #000;
}
.modalUserAddress {
  top: 20px;
}
.wrapper_titleList {
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.wrapper_titleList > i {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.wrapperUserAddress_fullName {
  font-weight: 500;
  margin-right: 20px;
}
.styleIssTable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer;
}
.modalUserAddress .ant-list-item-action .actionList {
  color: #2673dd;
  font-size: 14px;
  font-weight: 400;
}
.modalUserAddress .formItemSwitch {
  margin: 0 0 5px;
}
.modalUserAddress .textAdress {
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  margin-left: 15px;
  color: #000;
}
.wrapperSwitch {
  display: flex;
  align-items: center;
}
.wrapperSwitch:not(:last-child) {
  margin-bottom: 5px;
}
.tagDefault {
  color: #40d0bd;
  background-color: #e8f9f7;
}
.tagPickUp {
  color: #ee4d2d;
  background-color: #fff1f0;
}
.tagReturn {
  color: #eda500;
  background-color: #fff7e0;
  margin-top: 5px;
}
.wrapperSwitch > span {
  font-size: 14px;
  color: #000;
  margin-left: 15px;
}
.modalUserAddress .ant-modal-body,
.modalUserAddress .ant-modal-footer {
  background: #fff !important;
}
.icon {
  font-size: 20px;
  padding: 5px;
  right: 20px;
  border-radius: 50px;
  z-index: 5;
  cursor: pointer;
  background: #eee;
  box-shadow: #333333 2px 0px 5px 0px;
  position: absolute;
  top: -180px;
  right: -70px;
}
.dropDownCustomStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100px;
  border: 1px solid #e0e7ee;
  border-radius: 20px !important;
}
.dropDownCustomStatus span {
  margin-right: 5px;
}
.menu_icon {
  padding: 0;
}
.menu_icon .menu_icon_disable {
  opacity: 0.5;
  cursor: not-allowed;
}
.menu_icon .ant-dropdown-menu-item {
  padding: 10px 17px;
  color: #252525;
}
.menu_icon .ant-dropdown-menu-item:hover {
  background: #f6f7f9 0% 0% no-repeat padding-box;
}
.menu_icon .ant-dropdown-menu-item:hover div {
  color: #252525;
}
.btn_status1 {
  background: #4bac4d !important;
  border-color: #4bac4d !important;
  color: #fff !important;
}
.btn_statusDuyet {
  background: #05a9d6 !important;
  border-color: #05a9d6 !important;
  color: #fff !important;
}
.btn_statusAn {
  background: #d9363e 0% 0% no-repeat padding-box !important;
  border-color: #d9363e !important;
  color: #fff !important;
}
.btn_status0 {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-color: #e0e7ee !important;
  color: #222 !important;
}
.dropDownCustomStatus img {
  margin-left: 12px;
}
.pagiCustom {
  text-align: right;
  background: #fff !important;
  padding: 10px 0;
}
.pagiCustom .ant-pagination-prev,
.pagiCustom .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  line-height: 22px;
}
.pagiCustom .ant-select-selector {
  align-items: center !important;
  height: 24px !important;
}
.pagiCustom .ant-pagination-item {
  display: inline-block;
  min-width: 24px;
  height: 24px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.btn_edit {
  color: #1888E8;
  background-color: #fff;
  border-color: #1888E8;
  padding: 0px 10px !important;
  font-weight: 500 !important;
  min-width: 65px !important;
}
.menu_icon {
  padding: 0;
}
.menu_icon .ant-dropdown-menu-item {
  padding: 10px 17px;
  color: #252525;
}
.menu_icon .ant-dropdown-menu-item:hover {
  background: #f6f7f9 0% 0% no-repeat padding-box;
  color: #252525 !important;
}
.listCommentCms {
  background: #fff;
  padding: 10px 5px;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.listCommentCms .ant-list-item-action {
  text-align: right;
}
.listCommentCms_comment {
  color: #000;
  font-size: 16px;
  margin-bottom: 20px;
}
.listCommentCms .ant-list-items {
  display: flex;
  flex-wrap: wrap;
}
.listCommentCms_img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border: 1px solid #eee;
}
.listCommentCms_name {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
.listCommentCms .ant-list-item-meta-title {
  margin: 0;
}
.listCommentCms .ant-list-items .ant-list-item {
  width: 100%;
}
.listCatalog .ant-list-item-meta {
  align-items: center;
}
.listCommentCms_productName {
  font-size: 14px;
  color: #222;
}
.listCommentCms_product {
  font-size: 14px;
  opacity: 0.5;
  margin-right: 10px;
}
.listCommentCms_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listCommentCms_des {
  display: flex;
  align-items: center;
}
.listCommentCms_date {
  margin-top: 0.75rem;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.54);
}
.dropDownCustom {
  margin-left: 15px;
}
.dropDownCustom:hover {
  border: 1px solid #e0e7ee;
}
.btn_edit:hover {
  color: #1888E8;
  background-color: #fff;
  border-color: #1888E8;
}
.btn_edit:active {
  color: #1888E8;
  background-color: #fff;
  border-color: #1888E8;
}
.btn_edit:visited {
  color: #1888E8;
  background-color: #fff;
  border-color: #1888E8;
}
.btn_edit:focus {
  color: #1888E8;
  background-color: #fff;
  border-color: #1888E8;
}
.tabCustom .ant-tabs-nav {
  margin: 0;
  margin-bottom: 1px;
  padding: 0 8px;
}
.tabCustomDesign .ant-tabs-nav {
  padding: 0;
}
.tabCustomDesign .ant-tabs-tab {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.77px;
  color: #222;
  text-transform: uppercase;
  margin-right: 5px;
  background-color: #f3f3f3;
  border: 1px solid #e6e6e6;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  padding: 10px 18px;
}
.tabCustomDesign .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #ffffff;
}
.tabCustomDesign .ant-tabs-ink-bar {
  display: none !important;
}
.tabCustomDesign .ant-table-filter-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonSubmit {
  margin-right: 0px;
  float: right;
  height: 30px;
  border-radius: 4px;
  color: #fff;
  border: unset !important;
}
.buttonReset {
  font-size: 13px;
  color: #222;
  letter-spacing: 0.52px;
  font-weight: 700;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  margin-left: 20px;
  height: 30px;
  text-transform: uppercase;
}
.buttonReset2 {
  color: #fff !important;
  margin-left: 20px;
}
.modalFilter {
  margin: 0;
}
.modalFilter .ant-modal-content {
  border-radius: 0;
  background: #fff;
}
.modalFilter .ant-form-item-label {
  padding: 0;
}
.modalFilter .buttonSubmit {
  margin-top: 10px;
  margin-right: 0;
}
.buttonModalFilter {
  padding: 5px;
  background: #fff;
  cursor: pointer;
  display: none;
  color: #1888E8;
}
.red td:first-child {
  border-left: 4px solid red;
}
.yellow td:first-child {
  border-left: 4px solid yellow;
}
.green td:first-child {
  border-left: 4px solid green;
}
.red6 td:nth-child(6) {
  border-left: 4px solid red;
  background: #ffc9c9 !important;
}
.yellow6 td:nth-child(6) {
  border-left: 4px solid yellow;
  background: #f9f3c0 !important;
}
.green6 td:nth-child(6) {
  border-left: 4px solid green;
  background: #77d677 !important;
}
.footer {
  background-color: #fafafa;
}
.itemAdress {
  padding: 10px !important;
  border-bottom: 1px solid #f0f0f0 !important;
  cursor: pointer;
}
.itemAdress:hover {
  background: #eeeeee;
  border-radius: 10px;
}
.checkAllAdmin {
  display: flex;
}
.checkSelectAdmin {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.checkNumberAdmin {
  padding: 0 5px;
  font-size: 21px;
  font-weight: 600;
  color: red;
}
.insuranceProduct {
  border: 1px solid #eee;
}
.insuranceProduct .ant-list-header {
  border: solid 1px #c5e7f2;
  color: #292929;
  padding: 8px 10px;
  background-color: #dcf4fc;
}
.insuranceProduct .label-list {
  color: #292929;
  font-weight: 700;
  font-size: 13px;
}
.insuranceProduct .ant-list-item {
  padding: 12px 10px;
}
.insuranceProduct .ant-empty-normal {
  margin: 0;
}
.settingSource {
  border: 1px solid #eee;
  padding: 0px 6px;
}
.settingSource .headerSource {
  background-color: #dcf4fc;
  color: #292929;
  font-weight: 500;
  border: solid 1px #c5e7f2;
}
.settingSource .fa-expand-arrows-alt {
  margin-right: 10px;
  cursor: pointer;
}
.header_input {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #d1d1d1;
  background-color: #fff;
  padding: 0 10px;
  position: relative;
}
.header_input > i {
  font-size: 16px;
  color: #999;
}
.header_input .search {
  margin-right: 10px;
}
.header_input .plus {
  margin-left: 10px;
}
.header_input .header_inputsearch {
  height: 40px;
  border-bottom: none !important;
}
.header_input .header_inputsearch.ant-input:focus,
.header_input .header_inputsearch.ant-input-focused {
  box-shadow: unset;
}
.header_input .list-suggestion {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  width: 100%;
  z-index: 100;
}
.header_input .info-item {
  font-size: 12px;
  color: #111;
  font-family: Arial, Helvetica, sans-serif;
}
.header_input .info-item > span {
  margin-right: 10px;
}
.header_input .ant-list-item {
  padding: 7px 12px;
  cursor: pointer;
}
.header_input .ant-list-item:hover {
  background-color: #8CBCE53D !important;
}
.header_input .ant-list-item-meta-avatar {
  margin-right: 25px;
}
.header_input .ant-avatar {
  width: 50px;
  height: 50px;
  border-radius: unset;
}
.header_input .ant-list-item-meta-title {
  font-size: 13px;
}
@media screen and (min-width: 992px) and (max-width: 1240px) {
  .wrapperTable {
    padding-left: 30px;
  }
  .cardCustom {
    width: 100%;
  }
  .modal_selectNganhHang .selectNganhHang__box {
    width: 23%;
  }
  .selectTabs_component .selectNganhHang__box {
    width: 31%;
  }
  .buttonReset {
    margin-left: 5px;
  }
}
@media screen and (max-width: 992px) {
  .tableListForm .ant-form-item {
    padding: 0px;
  }
  .cardCustom {
    width: 100%;
  }
  .modal_selectNganhHang .selectNganhHang__box {
    width: 50%;
  }
  .selectTabs_component .selectNganhHang__box {
    width: 45%;
  }
}
@media screen and (max-width: 768px) {
  .tableListForm .ant-form-item {
    padding: 0px;
    margin-right: 8px;
  }
  .buttonSubmit {
    margin-right: 8px;
  }
  .newTableListForm .ant-form-item > .ant-form-item-label {
    padding-bottom: 5px;
    line-height: 32px;
  }
  .newTableListForm .ant-form-item label {
    height: 32px;
  }
  .buttonReset {
    margin-left: 10px;
  }
  .buttonReset2 {
    margin-left: 10px;
  }
}
@media screen and (max-width: 576px) {
  .buttonModalFilter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
  }
  .buttonModalFilter > i {
    background: #dbdbdb;
    /* width: 20px; */
    padding: 5px;
    border-radius: 50%;
    margin-left: 10px;
  }
  .modal_selectNganhHang .selectNganhHang__box {
    width: 90%;
  }
  .selectTabs_component .selectNganhHang__box {
    width: 90%;
  }
  .headerLabelRenderForm {
    display: none;
  }
  .wrapperTable {
    margin-top: 0;
  }
  .col_card_custom {
    margin-top: 15px;
  }
  .tableListForm {
    display: none;
  }
  .tableListForm .ant-form-item {
    padding: 0px 24px;
    margin-right: 0px;
  }
  .buttonSubmit {
    margin-right: 24px;
  }
  .renderTree > .dragtreeTitle > .row_8 {
    position: relative;
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .renderTree > .dragtreeTitle .row_3 {
    position: relative;
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .renderTree > .dragtreeTitle .row_5 {
    display: block;
    flex: 0 0 16.833%;
    max-width: 16.833%;
    position: relative;
  }
}
.detailLocation {
  padding: 10px;
  border-bottom: 1px solid #eee;
}
.detailLocation i {
  float: right;
  line-height: 20px;
  color: red;
  cursor: pointer;
}
.selectLocation .ant-select-selector {
  border: unset !important;
  border-bottom: 1px solid #eee !important;
  box-shadow: unset !important;
  border-radius: unset !important;
}
@media screen and (max-width: 768px) {
  .renderTree {
    width: 100%;
    overflow-x: auto;
  }
  .renderTree > .ant-spin-nested-loading {
    position: relative;
    width: 200%;
    overflow-x: auto;
  }
  .renderTree .dragtreeTitle {
    padding: 8px 15px 8px 40px;
    font-size: 14px;
    background: #1888E81c;
    font-weight: 500;
    width: 200%;
    overflow-x: auto;
  }
  .listCommentCms .ant-list-items .ant-list-item {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .checkSelectAdmin {
    display: none;
  }
  .wrapper_titleList {
    align-items: unset;
    flex-direction: column-reverse;
  }
  .wrapper_titleList .listCommentCms_btn {
    margin-bottom: 5px;
  }
  .wrapper_titleList .dropDownCustomStatus {
    margin: 0 !important;
    margin-right: auto !important;
  }
  .listCommentCms .ant-list-items .ant-list-item {
    width: 100%;
  }
  .listCommentCms_img {
    width: 50px;
  }
  .renderTree > .dragtreeTitle > .row_8 {
    position: relative;
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .renderTree > .dragtreeTitle .row_3 {
    display: block;
    flex: 0 0 19.5%;
    max-width: 19.5%;
    position: relative;
  }
  .renderTree > .dragtreeTitle .row_5 {
    display: block;
    flex: 1 0 13.833%;
    max-width: 13.833%;
    position: relative;
  }
  .row_custom {
    width: 100%;
    line-height: 30px;
  }
  .row_custom > .row_8 {
    position: relative;
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row_custom .row_3 {
    display: block;
    flex: 0 0 19.5%;
    max-width: 19.5%;
    position: relative;
  }
  .row_custom .row_5 {
    display: block;
    flex: 1 0 13.833%;
    max-width: 13.833%;
    position: relative;
  }
}
td.ant-table-column-sort {
  background: inherit;
}
.rowChecked {
  background-color: #e5e5e5;
  border-radius: 15px;
  color: black;
  font-weight: 500;
  height: 50px;
  cursor: pointer;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.rowUnCheck {
  height: 50px;
  cursor: pointer;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.note {
  padding: 0 15px;
}
.modalCloseRed .ant-modal-close-x {
  font-size: 1.5rem;
}
.modalCloseRed .ant-modal-content {
  background-color: #f4f4f4;
}
.modalCloseRed .ant-modal-title {
  font-size: 20px;
}
.dragtree {
  padding: 0px 15px 5px !important;
  overflow: auto;
  height: 60vh;
}
.dragtree .ant-tree-treenode {
  border-top: 1px solid #eee;
  padding: unset !important;
}
.dragtree .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  padding: 3px 0;
}
.dragtree .ant-tree-node-content-wrapper:hover {
  background-color: #e6f8fe !important;
  padding: 0 5px;
  border-radius: 5px;
}
.dragtreeSetting {
  padding: 10px 0px !important;
}
.dragtreeSetting .ant-tree-treenode {
  padding: unset !important;
}
.dragtreeSetting .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  padding: 5px 0;
}
.dragtreeSetting .ant-tree-node-content-wrapper:hover,
.dragtreeSetting .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: unset !important;
}
.dragtreeTitle {
  padding: 8px 15px 8px 40px;
  font-size: 14px;
  background: #1888E81c !important;
  font-weight: 500;
}
.noData {
  background: #fff;
  padding: 20px;
  text-align: center;
  font-size: 1rem;
}
.modalHome {
  top: 0;
  width: 50% !important;
}
@media screen and (max-width: 576px) {
  .modalHome {
    width: 100% !important;
  }
}
.listPlace {
  background: #fff;
  border-radius: 10px;
}
.listItemPlace {
  border-top: 1px solid #eee;
  padding: 5px 10px;
}
.listItemPlaceIcon {
  float: right;
}
.listItemPlaceIcon a {
  margin-left: 10px;
}
.descriptionWrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}
.descriptionLabel {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}
.salesExtra a {
  color: #222222;
  margin-left: 24px;
}
.salesExtra a:hover {
  color: #0090D5;
}
.salesExtra a.currentDate {
  color: #0090D5;
}
.print_table table {
  page-break-after: auto;
}
.print_table tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
.print_table td {
  page-break-inside: avoid;
  page-break-after: auto;
}
.print_table thead {
  display: table-row-group;
}
.print_table tfoot {
  display: table-footer-group;
}
.table_static .ant-table-tbody > tr > td,
.table_static .table_static .ant-table-thead > tr > th {
  border: 1px solid #f0f0f0 !important;
}
.table_static .ant-table-tbody > tr > td {
  background: #fff !important;
}

.antd-pro-component-dashs-page-header-wrapper-grid-content-main {
  width: 100%;
  height: 100%;
  min-height: 100%;
  transition: 0.3s;
}
.antd-pro-component-dashs-page-header-wrapper-grid-content-main.antd-pro-component-dashs-page-header-wrapper-grid-content-wide {
  max-width: 100%;
  margin: 0 auto;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-component-dashs-page-header-wrapper-index-content {
  margin: 0px 30px 10px;
}
@media screen and (max-width: 576px) {
  .antd-pro-component-dashs-page-header-wrapper-index-content {
    margin: 10px 15px 0px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
* {
  scrollbar-color: rgba(0, 0, 0, 0.2) white !important;
  scrollbar-width: thin !important;
}
.content {
  margin: auto;
  width: 100%;
  padding-top: 50px;
}
@media screen and (max-width: 768px) {
  .content {
    padding-top: 0px;
  }
}
.RowhelpKey {
  margin: 15px 0;
}
.layout {
  background: #F4F4F4;
}
.helpKey {
  border: 2px solid #777;
  border-radius: 4px;
  padding: 5px;
  margin-right: 15px;
  display: inline-block;
  min-width: 35px;
  font-weight: bold;
  color: #777;
  background: #fff;
  text-align: center;
}
.ant-btn {
  border-radius: 3px !important;
}
.ant-btn-primary {
  background: #1888E8 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 5px;
}
.ant-btn-danger {
  border: 1px solid red;
  background-color: #ef4b44 !important;
  box-shadow: 0px 0px 5px #00000029;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  opacity: 0.8;
}
.ant-btn[disabled] {
  background: #f5f5f5 !important;
}
.ant-legacy-form-item {
  margin-bottom: 15px;
  width: 100% !important;
}
.ant-form-item-label > label,
.ant-legacy-form-item-label > label {
  color: #222;
  letter-spacing: 0.67px;
  font-weight: 500;
}
.ant-table-pagination.ant-pagination {
  margin: 10px 0 !important;
}
.ant-message-notice {
  padding: 0px;
}
.ant-message-notice-content {
  width: 50vw;
  padding: 0px;
}
.ant-message-error {
  background-color: #fff1f0 !important;
  border: 1px solid#ffa39e !important;
  padding: 10px 0;
  width: 100%;
}
.ant-message-success {
  border: 1px solid#b7eb8f !important;
  background-color: #f6ffed !important;
  padding: 10px 0;
  width: 100%;
}
.ant-message-warning {
  border: 1px solid#ffe58f !important;
  background-color: #fffbe6 !important;
  padding: 10px 0;
  width: 100%;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  width: 6px;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled):not(.ant-select-item-option-selected),
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  color: #fff;
}
.ant-switch-handle {
  right: unset !important;
  left: 2px !important;
}
.ant-switch-checked {
  background-color: #34c38f !important;
}
.ant-switch-checked .ant-switch-handle {
  left: unset !important;
  right: 2px !important;
}
.ant-menu-submenu-popup ul li {
  border-bottom: unset;
}
.ant-menu-submenu-popup ul li:hover {
  background: #eee;
}
.ant-dropdown-menu-item-active {
  color: #fff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  color: #fff !important;
}
.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > a:hover {
  color: #fff !important;
}
.ant-select-item-option {
  border-top: 1px solid #f0f0f0;
}
.ant-select-item-option-content {
  text-overflow: unset !important;
  white-space: unset !important;
}
.ant-modal {
  max-width: unset;
  padding-bottom: 0;
}
.ant-modal-close-x {
  font-size: 1.5rem;
}
.ant-modal-content {
  background-color: #F4F4F4;
  border-radius: 10px;
  box-shadow: unset;
}
.ant-modal-title {
  font-size: 20px;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #0090D51A !important;
}
.header_banner {
  position: relative;
  height: 60px;
  background-color: #1888E8;
}
.header_banner__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 1;
}
.header_banner__content {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
.header_banner__logo {
  width: 50px;
  flex-shrink: 0;
}
.header_banner__logo > img {
  width: 100%;
}
.header_banner__text {
  flex: 1;
  padding-left: 15px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.01px;
  color: #fff;
  text-transform: uppercase;
}
.header_banner__buttonLogin {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  padding: 5px 10px;
  /* margin: 12px 10px 0 10px; */
  min-width: 122px;
  font-size: 13px;
  color: #fff;
  line-height: 22px;
  display: inline-block;
  background-color: #0078b6;
  border: 0;
  text-align: center;
  cursor: pointer;
}
.header_banner__buttonLogin > i {
  margin-right: 5px;
}
.header_banner__iconback {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  background: #0078b6;
}
.header_map {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  height: 60px;
  background-image: linear-gradient(-147deg, #eb678e 0%, rgba(0, 0, 0, 0.5) 100%);
  background: #1888E8;
  z-index: 200;
  display: block;
}
.header_map .container-fluid::after {
  clear: both;
}
.header_map .container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
}
.header_map .hlogo {
  height: 100%;
  display: flex;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}
.header_map .hlogo .headTopimage_map {
  height: 60px;
  width: 60px;
  object-fit: contain;
  padding: 5px;
  cursor: pointer;
}
.header_map .hlogo .headTitle_map {
  height: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .header_map .form-search-top {
    width: auto !important;
  }
}
.header_map .hlogo .headTitle_map h2 {
  font-size: 13px;
  margin-bottom: 0px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
}
.header_map .hlogo .headTitle_map div {
  line-height: 4px;
  font-size: 14px;
  color: #fff;
}
.header_map .hlogo h1,
.header_map .hlogo p {
  position: relative;
  z-index: 1000;
  margin: 3px 0 0 1px;
  width: 394px;
  height: 54px;
  font-size: .1%;
  line-height: .1;
}
.header_map .hlogo h1,
.header_map .hlogo p {
  background-size: auto;
  -webkit-background-size: 100%;
  background-size: 100%;
}
.header_map .header-right {
  display: flex;
}
.header_map .account-dropdown {
  position: relative;
  z-index: 100;
}
.header_map .header-right::after {
  clear: both;
}
.dropup,
.dropdown {
  position: relative;
}
.header_map .account-dropdown > .dropdown > a {
  position: relative;
  padding: 14px 26px 14px 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  min-width: initial;
  min-height: 60px;
  align-items: center;
}
.header_map .account-dropdown .account-image {
  margin-right: 6px;
  display: inline-block;
  vertical-align: middle;
  width: 32px;
}
/* .header .account-dropdown .account-image img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: block;
  width: 32px;
  height: 32px;
} */
.header_map .account-dropdown .account-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
  display: inline-block;
  text-transform: none;
  vertical-align: middle;
}
.header_map .account-dropdown > .dropdown > a::after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -5px;
  content: "";
  display: inline-block;
  vertical-align: top;
  border: solid #fff;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 2px 2px 0;
  padding: 3px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 13px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.dropdown-menu {
  opacity: 0;
  filter: alpha(opacity=0);
  display: block;
  visibility: hidden;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
  transition: opacity .3s 0s, visibility 0s .3s, transform .3s 0s;
  z-index: -100;
}
.open > .dropdown-menu {
  opacity: 1;
  filter: alpha(opacity=100);
  display: block;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: opacity 0.3s 0s, visibility 0.3s 0s, -webkit-transform 0.3s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0.3s 0s, -moz-transform 0.3s 0s;
  transition: opacity .3s 0s, visibility .3s 0s, transform .3s 0s;
  z-index: 1500;
}
.header_map .account-dropdown .dropdown-menu {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  right: 0;
  left: auto;
  padding: 0;
  margin: 0;
  min-width: 192px;
  border: 0;
  overflow: hidden;
  color: #fff;
  background-color: #5280d5;
}
.header_map .account-dropdown > .dropdown.open a,
.header_map .account-dropdown > .dropdown > a:hover {
  color: #fff;
  background-color: #5280d5;
}
.header_map .account-dropdown .dropdown-menu > li {
  border-top: 1px solid #fff;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333;
  white-space: nowrap;
}
.header_map .account-dropdown .dropdown-menu > li > a {
  padding: 13px 10px 15px;
  line-height: 1.2;
  color: #fff;
}
.header_map .account-dropdown .dropdown-menu > li > a .align-icons,
.header_map .account-dropdown .dropdown-menu > li > a .align-nav-text {
  display: inline-block;
}
.header_map .account-dropdown .dropdown-menu > li > a .align-nav-text {
  vertical-align: middle;
}
.header_map .account-dropdown .dropdown-menu > li > a:hover,
.header_map .account-dropdown .dropdown-menu > li > a:focus {
  color: #ff0;
  background-color: #5280d5;
  text-decoration: underline;
}
.hotline {
  display: inline-block;
  background-color: transparent;
  position: fixed;
  bottom: 8px;
  right: 9px;
  transform: scale(1);
  opacity: 1;
  transition: transform 0.2s ease-in-out;
  z-index: 900;
}
.hotline a {
  cursor: pointer;
  display: flex;
  font-weight: 700;
  width: 100%;
  height: 100%;
  background-color: #237fcd;
  color: #fff;
  position: relative;
  padding: 5px;
  font-size: 12px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1023px) {
  .header_banner__text {
    font-size: 11px;
  }
  .header_banner__logo {
    width: 30px;
  }
}
@media screen and (max-width: 767px) {
  .content {
    width: unset;
    margin: 15px 0 0 0;
    padding-top: 0;
  }
  .header_banner__buttonLogin {
    display: none;
  }
  .header_banner__text {
    font-size: 13px;
  }
  .header_banner__text.phonebook {
    display: none;
  }
  .header_banner__logo {
    width: 35px;
  }
}

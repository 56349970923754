/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-component-dashs-global-header-index-header {
  height: 44px;
  padding: 0;
  background: #0090D5 !important;
  color: #fff !important;
  position: relative;
  z-index: 1;
}
.antd-pro-component-dashs-global-header-index-menuAvatar {
  text-align: center;
}
.antd-pro-component-dashs-global-header-index-logo {
  height: 44px;
  line-height: 44px;
  vertical-align: top;
  display: inline-block;
  padding: 0 0 0 24px;
  cursor: pointer;
  font-size: 20px;
}
.antd-pro-component-dashs-global-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
}
.antd-pro-component-dashs-global-header-index-menu {
  border-radius: 5px !important;
}
.antd-pro-component-dashs-global-header-index-menu .ant-dropdown-menu-item {
  padding: 10px 35px;
}
.antd-pro-component-dashs-global-header-index-menu .ant-dropdown-menu-item i {
  width: 30px;
}
.antd-pro-component-dashs-global-header-index-menu .ant-dropdown-menu-item:hover {
  background-color: #eee !important;
  color: #222 !important;
}
.antd-pro-component-dashs-global-header-index-select {
  width: 200px;
}
.antd-pro-component-dashs-global-header-index-none {
  display: none;
}
.antd-pro-component-dashs-global-header-index-trigger {
  font-size: 20px;
  height: 44px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  padding: calc((44px - 20px) / 2) 24px;
}
.antd-pro-component-dashs-global-header-index-trigger:hover {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-component-dashs-global-header-index-right {
  float: right;
  height: 100%;
  width: auto;
  border-left: 1px solid #D8D8D8;
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-action {
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  transition: all 0.3s;
  height: 100%;
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-action > i {
  vertical-align: middle;
  color: #222222;
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-action.opened {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-search {
  padding: 0 12px;
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-search:hover {
  background: transparent;
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-account .antd-pro-component-dashs-global-header-index-avatar {
  margin: calc((44px - 40px) / 2) 0;
  margin-right: 8px;
  color: #0090D5;
  background: rgba(255, 255, 255, 0.85);
  vertical-align: top;
  width: 40px;
  height: 40px;
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-accountName {
  cursor: pointer;
  font-weight: 500;
}
.antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-accountName > i {
  margin-left: 15px;
  color: #CCCCCC;
}
.antd-pro-component-dashs-global-header-index-dark {
  height: 44px;
}
.antd-pro-component-dashs-global-header-index-dark .antd-pro-component-dashs-global-header-index-action {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-component-dashs-global-header-index-dark .antd-pro-component-dashs-global-header-index-action > i {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-component-dashs-global-header-index-dark .antd-pro-component-dashs-global-header-index-action:hover,
.antd-pro-component-dashs-global-header-index-dark .antd-pro-component-dashs-global-header-index-action.opened {
  background: #0090D5;
}
.antd-pro-component-dashs-global-header-index-dark .antd-pro-component-dashs-global-header-index-action .ant-badge {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-component-dashs-global-header-index-inboxIcon {
  font-size: 16px;
}
@media only screen and (max-width: 768px) {
  .antd-pro-component-dashs-global-header-index-header .ant-divider-vertical {
    vertical-align: unset;
  }
  .antd-pro-component-dashs-global-header-index-header .antd-pro-component-dashs-global-header-index-name {
    display: none;
  }
  .antd-pro-component-dashs-global-header-index-header i.antd-pro-component-dashs-global-header-index-trigger {
    padding: 22px 12px;
  }
  .antd-pro-component-dashs-global-header-index-header .antd-pro-component-dashs-global-header-index-logo {
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
  }
  .antd-pro-component-dashs-global-header-index-header .antd-pro-component-dashs-global-header-index-right {
    position: absolute;
    right: 12px;
    top: 0;
  }
  .antd-pro-component-dashs-global-header-index-header .antd-pro-component-dashs-global-header-index-right .antd-pro-component-dashs-global-header-index-account .antd-pro-component-dashs-global-header-index-avatar {
    margin-right: 0;
    margin-top: 5px;
    height: 30px;
    width: 30px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-component-dashs-sider-menu-index-logo {
  height: 44px;
  position: relative;
  line-height: 44px;
  transition: all 0.3s;
  background: #002140;
  overflow: hidden;
}
.antd-pro-component-dashs-sider-menu-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
  padding: 3px 0px;
  margin-top: -5px;
  margin-left: 50px;
}
.antd-pro-component-dashs-sider-menu-index-logo h1 {
  color: white;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  margin: 0 0 0 12px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.antd-pro-component-dashs-sider-menu-index-sider {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
}
.antd-pro-component-dashs-sider-menu-index-sider.antd-pro-component-dashs-sider-menu-index-fixSiderbar {
  position: fixed;
  top: 0;
  left: 0;
}
.antd-pro-component-dashs-sider-menu-index-sider.antd-pro-component-dashs-sider-menu-index-fixSiderbar .ant-menu-root {
  overflow-y: auto;
  height: calc(100vh - 44px);
}
.antd-pro-component-dashs-sider-menu-index-sider.antd-pro-component-dashs-sider-menu-index-light {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  background-color: white;
}
.antd-pro-component-dashs-sider-menu-index-sider.antd-pro-component-dashs-sider-menu-index-light .antd-pro-component-dashs-sider-menu-index-logo {
  background: white;
  box-shadow: 1px 1px 0 0 #f0f0f0;
}
.antd-pro-component-dashs-sider-menu-index-sider.antd-pro-component-dashs-sider-menu-index-light .antd-pro-component-dashs-sider-menu-index-logo h1 {
  color: #0090D5;
}
.antd-pro-component-dashs-sider-menu-index-sider.antd-pro-component-dashs-sider-menu-index-light .ant-menu-light {
  border-right-color: transparent;
}
.antd-pro-component-dashs-sider-menu-index-icon {
  width: 13px;
  margin-right: 10px;
}
.ant-menu-vertical.ant-menu-sub {
  min-width: 260px !important;
}
.top-nav-menu {
  background: #1888E8 !important;
  color: #222222 !important;
  font-weight: 500;
}
.top-nav-menu li.ant-menu-item {
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  padding: 0 20px;
  border: unset !important;
  box-shadow: unset !important;
  top: 0px !important;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #222222 !important;
  border-bottom: unset !important;
  background-color: #0078b6 !important;
  border-radius: 5px;
  top: 0px !important;
}
.top-nav-menu li.ant-menu-item a {
  color: #fff !important;
  text-transform: inherit;
  font-weight: 500;
}
.ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 15px;
  text-transform: inherit;
  font-weight: 500;
}
.drawer .drawer-content {
  background: #001529;
}
.ant-menu-inline-collapsed > .ant-menu-item .sider-menu-item-img + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .sider-menu-item-img + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .sider-menu-item-img + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-item .sider-menu-item-img + span,
.ant-menu-submenu-title .sider-menu-item-img + span {
  opacity: 1;
}
.ant-menu-item,
.ant-menu-submenu-inline {
  border-bottom: unset;
}
.ant-menu-item:last-child,
.ant-menu-submenu-inline:last-child {
  border-bottom: none;
}
.ant-menu-submenu > .ant-menu > .ant-menu-item {
  height: 35px;
  line-height: 35px;
}
.antd-pro-component-dashs-sider-menu-index-menuHeader {
  background-color: unset !important;
}
.antd-pro-component-dashs-sider-menu-index-menuHeader ul {
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.antd-pro-component-dashs-sider-menu-index-menuHeader ul li {
  margin: 0 !important;
  height: 44px !important;
  line-height: 44px !important;
}
.antd-pro-component-dashs-sider-menu-index-menuHeader ul li a {
  color: #222 !important;
}
.antd-pro-component-dashs-sider-menu-index-menuHeader ul .ant-menu-item-selected {
  background-color: #eee !important;
}
.antd-pro-component-dashs-sider-menu-index-menuHeader ul .ant-menu-item-selected a {
  color: #222 !important;
}
